import React from "react"
import { useThemeUI } from "theme-ui"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import BarChart from "../../../../../components/bar-chart"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import NoBreak from "../../../../../components/no-break"
import Stack from "../../../../../components/stack"

const Page = () => {
  const context = useThemeUI()
  const { theme } = context

  const chartData = [
    { id: 1, color: theme.colors["chart"], value: 1, name: "Eismeer" },
    { id: 2, color: theme.colors["chart"], value: 1, name: "Mündung" },
    { id: 3, color: theme.colors["chart"], value: 5, name: "Fluss" },
    { id: 4, color: theme.colors["chart"], value: 8, name: "Brunnen" },
    { id: 5, color: theme.colors["chart"], value: 12, name: "See, die" },
    { id: 6, color: theme.colors["chart"], value: 16, name: "See, der" },
    { id: 7, color: theme.colors["chart"], value: 30, name: "Wellen" },
    { id: 8, color: theme.colors["chart"], value: 39, name: "Ozean" },
    { id: 9, color: theme.colors["chart"], value: 40, name: "Insel" },
    { id: 10, color: theme.colors["chart"], value: 42, name: "Wasser" },
    { id: 11, color: theme.colors["chart"], value: 46, name: "Bach" },
    { id: 12, color: theme.colors["chart"], value: 51, name: "Ufer" },
    { id: 13, color: theme.colors["chart"], value: 55, name: "Woge	" },
    { id: 14, color: theme.colors["chart"], value: 56, name: "Meer" },
    { id: 14, color: theme.colors["chart"], value: 59, name: "Quelle" },
    { id: 15, color: theme.colors["chart"], value: 100, name: "Strom" },
  ]

  const chartDataRiver = [
    { id: 1, color: theme.colors["chart"], name: "Dordogne", value: 2 },
    { id: 2, color: theme.colors["chart"], name: "Eden", value: 2 },
    { id: 3, color: theme.colors["chart"], name: "Lethe", value: 3 },
    { id: 4, color: theme.colors["chart"], name: "Garonne", value: 3 },
    { id: 5, color: theme.colors["chart"], name: "Main", value: 3 },
    { id: 6, color: theme.colors["chart"], name: "Cephissus", value: 4 },
    { id: 7, color: theme.colors["chart"], name: "Ilissus", value: 4 },
    { id: 8, color: theme.colors["chart"], name: "Donau", value: 5 },
    { id: 9, color: theme.colors["chart"], name: "Ister", value: 5 },
    { id: 10, color: theme.colors["chart"], name: "Indus", value: 6 },
    { id: 11, color: theme.colors["chart"], name: "Neckar", value: 11 },
    { id: 12, color: theme.colors["chart"], name: "Rhein", value: 21 },
  ]

  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/landschaften-und-orte/referenzen" />
      }
      navigation={navigation}
    >
      <Seo title="Landschaften & Orte markieren" />
      <Stack>
        <Heading as="h2" level={2}>
          Landschaften & Orte markieren
        </Heading>
        <Paragraph>
          Vor allem das Wasser und die Seefahrt dominieren die
          Landschaftsbeschreibungen des Gedichts. Mit dem Wasser fühlte sich
          Hölderlin seit jeher verbunden. Er wurde in Lauffen, einer Stadt am{" "}
          <NoBreak>Neckar</NoBreak>, geboren, wuchs in Nürtingen, ebenfalls am{" "}
          <NoBreak>Neckar</NoBreak>, auf und spielte dort wohl häufig mit seinen
          beiden Geschwistern am <NoBreak>Neckarstrand</NoBreak>. Dem{" "}
          <NoBreak>Neckar</NoBreak> blieb er Zeit seines Lebens verbunden. Auch
          in den letzten 36 Jahren seines Lebens, die er in dem heute nach ihm
          benannten Turm in <NoBreak>Tübingen</NoBreak> verbrachte, hatte er den{" "}
          <NoBreak>Neckar</NoBreak> aus den 5 Fenstern seines Turmzimmers
          täglich vor Augen.
        </Paragraph>
        <Paragraph>
          Doch auch der Rhein und die Donau, denen er auf seinen Wanderungen
          begegnete, beeindruckten ihn so sehr, dass sie immer wieder zum
          Gegenstand seiner Gedichte wurden. Sie waren ihm Wegweiser in die
          Ferne und Inbegriff für die Freiheit und Ungebundenheit.
        </Paragraph>
        <Stack space={3}>
          <Heading>Das Thema Wasser in Hölderlins Gedichten</Heading>
          <BarChart
            height="700px"
            data={chartData}
            colors={({ id, data }) => data["color"]}
            layout="horizontal"
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            isInteractive={false}
            enableGridY={false}
            margin={{ top: 0, right: 60, bottom: 0, left: 80 }}
            indexBy="name"
          />
        </Stack>
        <Stack space={3}>
          <Heading>Flüsse in Hölderlins Gedichten</Heading>
          <BarChart
            height="600px"
            data={chartDataRiver}
            colors={({ id, data }) => data["color"]}
            layout="horizontal"
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            isInteractive={false}
            enableGridY={false}
            margin={{ top: 0, right: 60, bottom: 0, left: 80 }}
            indexBy="name"
          />
        </Stack>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
